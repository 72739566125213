import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import prod1 from "../../images/cool.png";
import prod2 from "../../images/substance.png";
import prod4 from "../../images/dry.png";
import {Container } from "react-bootstrap";
// import "./ExploreProducts.css";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import ExoploreProductBanner from "../ExploreProductBanner/ExoploreProductBanner";

import ProcessedProducts from "../ExploreProducts/ProcessedProducts";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";
import AgroCareMarketPlaceNavbar from "../AgroCareMarketPlaceNavbar/AgroCareMarketPlaceNavbar";
import CategoryBanner from "../CategoryBanner/CategoryBanner";

const ExploreCareProducts = () => {
   
  const navData = [{to:"/onMarketplace", link:"Buy"},
  {to:"/onMarketplaceSell", link:"Sell"},
  // {to:"/onMarketplaceRFQ", link:"RFQ"}
]
  const[rawProduct,setRawProduct] = useState(true);
  const[semiProduct,setSemiProduct] = useState(false);
  // const[FMC,SetFMC] = useState(false)
  const[proccessedProduct,setproccessedproduct] = useState(false);
    const[seedProducts,setSeedProducts] = useState(false);

    const [userType] = useState(["AIC", "CBO", "AgroDealer" ]);

  const rawProductHandler =() =>{
    setRawProduct(true);
    setSemiProduct(false);
    setproccessedproduct(false);
    setSeedProducts(false);
  }

  const semiProductHandler =() =>{
    setSemiProduct(true);
    setRawProduct(false);
    setproccessedproduct(false);
    setSeedProducts(false);

  }
  const processedProductHandler =() =>{
    setproccessedproduct(true);
    setRawProduct(false);
    setSemiProduct(false);
    setSeedProducts(false);

  }

  const seedProductHandler =() =>{
    setSeedProducts(true);

    setproccessedproduct(false);
    setRawProduct(false);
    setSemiProduct(false);

  }

  const categoryImage = 
  {}

  return (
    <>
      <HeaderComponent className="noShadow" />
      {/* <AgroCareMarketPlaceNavbar/> */}
      <CategoryBanner bannerMessage={true} categoryImage={categoryImage} MarketCategoryType="AGR10" userType={userType} quote={"quote"}/>

   
      <FooterComponent />
    </>
  );
};

export default ExploreCareProducts;
